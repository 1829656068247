import React from "react";
import { Column, Grid } from "../blocks";
import { FormElement, Req, SubmitButton } from "../blocks/forms";
import ReCAPTCHA from "react-google-recaptcha";
import { Form } from "react-final-form";
import { graphql, useStaticQuery } from "gatsby";
import GatsbyImage from "gatsby-image";

import english from "../../../content/translations/en-CA/contact.json";
import french from "../../../content/translations/fr-CA/contact.json";

const RecaptchaKey = "6LdRTiAdAAAAAJKnd7u1oETrHAP417MWwQZsBjkJ"; //Points reCAPTCHA site Key
//const RecaptchaKey = "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"; //Local reCAPTCHA testing site Key

class RequestDemoForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formErrors: {
        first_name: "",
        last_name: "",
        company: "",
        phone: "",
        email: "",
        message: "",
        recaptcha: "",
      },
      valid: {
        first_name: false,
        last_name: false,
        email: false,
        company: false,
        phone: false,
        message: false,
        recaptcha: false,
      },
      formValid: false,
      first_name: "",
      last_name: "",
      company: "",
      phone: "",
      email: "",
      message: "",
      recaptcha: false,
      isFrench: props.fr ? true : false,
      messageValue: "",
    };
  }

  handleUserInput = e => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({ [name]: value }, () => {
      this.validateField(name, value);
    });

    if (e.target.name === "00NC0000005VhZf") {
      this.setState({ messageValue: value });
    }
  };

  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.formErrors;
    let first_nameValid = this.state.valid.first_name;
    let last_nameValid = this.state.valid.last_name;
    let companyValid = this.state.valid.company;
    let phoneValid = this.state.valid.phone;
    console.log("phoneValid", phoneValid);
    let emailValid = this.state.valid.email;
    let captchaValid = this.state.valid.recaptcha;

    switch (fieldName) {
      case "first_name":
        first_nameValid = value !== "";
        fieldValidationErrors.first_name = first_nameValid
          ? ""
          : this.state.isFrench
          ? "Un prénom est requis."
          : "A first name is required.";
        break;
      case "last_name":
        last_nameValid = value !== "";
        fieldValidationErrors.last_name = last_nameValid
          ? ""
          : this.state.isFrench
          ? "Un nom de famille est requis."
          : "A last name is required.";
        break;
      case "company":
        companyValid = value !== "";
        fieldValidationErrors.company = companyValid
          ? ""
          : this.state.isFrench
          ? "Un nom d’entreprise est requis."
          : "A company name is required.";
        break;
      case "phone":
        phoneValid = value.match(/^(?=.*[0-9])[- +()0-9]*$|^$/);
        fieldValidationErrors.phone =
          phoneValid || value === ""
            ? ""
            : this.state.isFrench
            ? "Le format est invalide."
            : "The format is invalid.";
        break;
      case "email":
        emailValid = !!value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        emailValid = emailValid && value.indexOf("@example.com") === -1;
        fieldValidationErrors.email = emailValid
          ? ""
          : this.state.isFrench
          ? "Adresse email invalide."
          : "Email is invalid.";
        break;
      case "recaptcha":
        captchaValid = value !== null;
        captchaValid = captchaValid && value !== undefined;
        fieldValidationErrors.recaptcha = captchaValid
          ? ""
          : this.state.isFrench
          ? "Le captcha doit être complété."
          : "Captcha must be completed.";
        break;
      default:
        break;
    }
    this.setState(
      {
        formErrors: fieldValidationErrors,
        valid: {
          first_name: first_nameValid,
          last_name: last_nameValid,
          company: companyValid,
          phone: phoneValid,
          email: emailValid,
          recaptcha: captchaValid,
        },
      },
      this.validateForm
    );
  }

  validateForm() {
    const isValid =
      this.state.first_name &&
      this.state.valid.last_name &&
      this.state.valid.company &&
      (!this.state.phone || this.state.valid.phone) &&
      this.state.valid.email &&
      this.state.valid.recaptcha;

    this.setState({ formValid: isValid });
  }

  errorClass(error) {
    return error.length === 0 ? "" : "has-error";
  }

  getFormError(field) {
    if (this.state.formErrors[field]) {
      return <small>{this.state.formErrors[field]}</small>;
    }
  }

  validateRecaptcha(value) {
    this.setState({ recaptcha: value }, () => {
      this.validateField("recaptcha", value);
    });
  }

  submitForm(e) {
    e.preventDefault();
    let messageVal = this.state.messageValue;

    if (this.state.isFrench) {
      messageVal = "FR-- " + messageVal;
    } else {
      messageVal = "EN-- " + messageVal;
    }

    document.getElementById("00NC0000005VhZf").value = messageVal;

    document.getElementById("demo-form").submit();
  }

  render() {
    return (
      <form
        id="demo-form"
        action="https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8"
        method="POST"
        style={{ width: "80%", margin: "auto" }}
      >
        <input type="hidden" name="oid" value="00D80000000LKWA" />
        <input
          type="hidden"
          name="retURL"
          value={`${this.props.returnURL}?submitted`}
        />
        <input type="hidden" name="lead_source" value="Web Lead Form" />
        <select id="recordType" name="recordType" hidden>
          <option value="0123b0000007zhT" selected>
            Solutions Lead Record
          </option>
        </select>
        <br></br>

        <FormElement {...this.props}>
          <label htmlFor="first_name">
            {this.state.isFrench === true ? "Prénom" : "First Name"}
            <Req />
          </label>
          <input
            id="first_name"
            maxlength="40"
            name="first_name"
            placeholder={this.state.isFrench === true ? "Prénom" : "First Name"}
            size="20"
            type="text"
            onChange={event => this.handleUserInput(event)}
          />
          {this.getFormError("first_name")}
        </FormElement>
        <FormElement {...this.props}>
          <label htmlFor="last_name">
            {this.state.isFrench === true ? "Nom" : "Last Name"}
            <Req />
          </label>
          <input
            id="last_name"
            className="last_name"
            maxLength="80"
            name="last_name"
            size="20"
            onChange={event => this.handleUserInput(event)}
            placeholder={this.state.isFrench === true ? "Nom" : "Last Name"}
            type="text"
          />
          {this.getFormError("last_name")}
        </FormElement>
        <FormElement {...this.props}>
          <label htmlFor="email">
            {this.state.isFrench === true
              ? "Courriel d'entreprise"
              : "Business Email"}
            <Req />
          </label>
          <input
            id="email"
            className="email"
            maxLength="80"
            name="email"
            onChange={event => this.handleUserInput(event)}
            placeholder={
              this.state.isFrench === true
                ? "Courriel d'entreprise"
                : "Business Email"
            }
            size="20"
            type="text"
          />
          {this.getFormError("email")}
        </FormElement>
        <FormElement {...this.props}>
          <label htmlFor="company">
            {this.state.isFrench === true ? "Compagnie" : "Company"}
            <Req />
          </label>
          <input
            id="company"
            className="company"
            maxLength="40"
            name="company"
            onChange={event => this.handleUserInput(event)}
            placeholder={this.state.isFrench === true ? "Compagnie" : "Company"}
            size="20"
            type="text"
          />
          {this.getFormError("company")}
        </FormElement>
        <FormElement {...this.props}>
          <label htmlFor="phone">
            {this.state.isFrench === true ? "Téléphone" : "Phone"}
          </label>
          <input
            id="phone"
            className="phone"
            maxLength="40"
            name="phone"
            onChange={event => this.handleUserInput(event)}
            placeholder={this.state.isFrench === true ? "Téléphone" : "Phone"}
            size="20"
            type="text"
          />
          {this.getFormError("phone")}
        </FormElement>

        <FormElement {...this.props}>
          <label htmlFor={"00NC0000005VhZf"}>
            {this.state.isFrench === true ? "Autre chose?" : "Anything else?"}
          </label>
          <textarea
            style={{ height: 150 }}
            id="00NC0000005VhZf"
            name="00NC0000005VhZf"
            placeholder={
              this.state.isFrench === true
                ? "Dites-nous plus sur vos objectifs de revenus auxiliaires ou vos projets."
                : "Tell us more about your ancillary revenue goals or project."
            }
            rows="3"
            type="text"
            wrap="soft"
            onChange={event => this.handleUserInput(event)}
            value={this.state.messageValue}
          />
          {this.getFormError("message")}
        </FormElement>

        <FormElement {...this.props}>
          <ReCAPTCHA
            hl={this.state.isFrench ? "fr" : "en"}
            id="contact-recaptcha"
            sitekey={RecaptchaKey}
            size="normal"
            onChange={value => this.validateRecaptcha(value)}
            onExpired={value => this.validateRecaptcha(value)}
          />
          {this.getFormError("recaptcha")}
        </FormElement>

        <SubmitButton
          onClick={event => this.submitForm(event)}
          type={"submit"}
          name="btnSubmit"
          disabled={!this.state.formValid}
          value={"Submit"}
          {...this.props}
        >
          <span className="button-text">
            {this.state.isFrench === true ? "Soumettre" : "Submit"}
          </span>
          <GatsbyImage fixed={this.props.icon.childImageSharp.fixed} />
        </SubmitButton>
      </form>
    );
  }
}

export default RequestDemoForm;

export const query = graphql`
  fragment SubmitButton on File {
    childImageSharp {
      fixed(width: 16, height: 16, webpQuality: 100) {
        ...GatsbyImageSharpFixed_withWebp
      }
    }
  }
`;
