import React from "react";

import { Layout, SEO } from "../../../components/structure";
import { Content, Hero } from "../../../components/blocks";
import { brand, font, mq } from "../../../styles";
import { graphql, useStaticQuery } from "gatsby";
import styled from "styled-components";
import RequestDemoForm from "../../../components/structure/RequestDemoForm";
import NewRequestDemoForm from "../../../components/structure/NewRequestDemoForm";

const RequestADemoPage = ({ location }) => {
  const data = useStaticQuery(graphql`
    query {
      heroImg: file(base: { eq: "home.jpg" }) {
        ...FullHeroBackground
      }
      rightArrowIcon: file(base: { eq: "arrow-right.png" }) {
        ...SubmitButton
      }
    }
  `);

  const ContentContainer = styled.div`
    ${font.imports.sherika}

    width: 95%;
    max-width: 1400px;
    margin: auto;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    @media only screen and ${mq.maxMd} {
      flex-direction: column;
    }

    .copy-content {
      flex-basis: 50%;
      height: 100%;
      padding: 100px 90px;

      @media only screen and ${mq.maxMd} {
        padding: 20px;
      }

      h2 {
        font-family: ${font.family.sherika};
        font-weight: 600;
        font-size: 56px;
        color: ${brand.colors.pg_blue};
        @media only screen and ${mq.maxMd} {
          font-size: 36px;
        }
      }

      p {
        font-family: ${font.family.sherika};
        font-weight: 700;
        font-size: 20px;
        color: ${brand.colors.pg_primary_dark};
        @media only screen and ${mq.maxMd} {
          font-size: 16px;
        }
      }
    }

    .form-content {
      flex-basis: 50%;
      width: 100%;
      height: 100%;

      .form-con {
        padding: 100px 80px;

        @media only screen and ${mq.maxMd} {
          padding: 20px 20px;
        }
      }

      .thank-you-message {
        font-family: ${font.family.sherika};
        background-color: ${brand.colors.pg_blue};
        font-weight: 600;
        font-size: 56px;
        width: 100%;
        min-height: 750px;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        padding: 100px 90px;
        margin-bottom: 0;
        @media only screen and ${mq.maxMd} {
          font-size: 36px;
          word-wrap: initial;
        }
      }
    }
  `;

  return (
    <Layout>
      <SEO
        title="Demandez une démonstration | Plusgrade"
        description={
          "Générez des revenus auxiliaires avec des expériences clients incroyables. Contactez-nous aujourd'hui pour en savoir plus."
        }
        keywords={"demo, trial"}
      />
      <Hero
        title={"Demandez une démonstration"}
        image={data["heroImg"]}
        imageStyle={{ objectPosition: "center top" }}
        fullBG
        backgroundGradient
      />

      <Content whiteBG noSpace>
        <ContentContainer>
          <div className="copy-content">
            <h2>Voir nos produits de revenus auxiliaires en action</h2>
            <p>
              Un membre de notre équipe commerciale vous contactera pour
              comprendre les besoins de votre entreprise et élaborer un plan
              personnalisé pour générer des revenus pour votre entreprise.
            </p>
          </div>
          <div className="form-content">
            {location.search.indexOf("submitted") === -1 ? (
              <div className="form-con">
                <NewRequestDemoForm
                  returnURL={location.href}
                  icon={data["rightArrowIcon"]}
                  fr
                ></NewRequestDemoForm>
              </div>
            ) : (
              <h2 className="thank-you-message">
                Nous vous remercions de votre demande. Un membre de notre équipe
                vous contactera dans les plus brefs délais.
              </h2>
            )}
          </div>
        </ContentContainer>
      </Content>
    </Layout>
  );
};

export default RequestADemoPage;
